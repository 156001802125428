import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import { Button } from '@gik/ui/Button';
import type { LandingPageFAQ } from '../types/LandingPageFAQ';
import { SvgIcon } from '@gik/ui/SvgIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { navigateTo } from '@/utils/navigateTo';

export interface IFAQCardProps {
  faq: LandingPageFAQ;
}

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4757-8720&m=dev
 */
function FAQCardComp({ faq: { icon, iconClassName, title, content, linkUrl, linkTitle, onClick } }: IFAQCardProps) {
  const bem = useBemCN('faq-card');

  const onFAQClicked = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      Analytics.fireEvent(AnalyticsEvents.FAQClicked, {
        feature: title,
        location: window.location.pathname,
      });

      onClick?.();
      if (linkUrl) navigateTo(linkUrl);
    },
    [linkUrl, onClick, title]
  );

  return (
    <Layout {...bem()} gap={4} flex={1}>
      <aside
        className={iconClassName}
        style={{
          flexShrink: 0,
        }}
      >
        <SvgIcon size={'2xl'} Icon={icon} />
      </aside>
      <Layout direction={'column'} align={'start'} perpendicular={'start'} gap={3}>
        <h3 className={titleClassNames.join(' ')}>{title}</h3>
        <p className={paragraphClassNames.join(' ')}>{content}</p>
        <Button
          variant={'primary-link'}
          type={linkUrl ? 'a' : undefined}
          size={'sm'}
          href={linkUrl}
          onClick={onFAQClicked}
          append={<SvgIcon Icon={ChevronRightIcon} />}
        >
          {linkTitle}
        </Button>
      </Layout>
    </Layout>
  );
}

const titleClassNames = [
  'tw-m-0',
  'tw-text-neutral-900',
  'tw-font-heading',
  'tw-text-xl',
  'tw-font-bold',
  'tw-leading-none',
];

const paragraphClassNames = [
  'tw-m-0',
  'tw-text-neutral-700',
  'tw-font-body',
  'tw-text-base',
  'tw-leading-tight',
  'md:tw-text-neutral-700',
  'md:tw-text-base',
  'md:tw-font-normal',
  'md:tw-leading-tight',
];

export const FAQCard = withComponentErrorBoundary(FAQCardComp);
